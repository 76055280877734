// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:565d57b3-8d92-4ff2-885a-f24e704cc22f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Ydw4tawbO",
    "aws_user_pools_web_client_id": "2elbrbb20jvapub0emr15ejm9f",
    "oauth": {},
    "aws_content_delivery_bucket": "ldc-virtual-terminal-staging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1hu0yrij664e9.cloudfront.net"
};


export default awsmobile;
